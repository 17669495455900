<template>
    <div>
        <CompTable title="机构标签设置" ref="comp_table" isSelector :columns="columns" :table-api="tableApi" @on-change-selection="onChangeSelection">
            <template #search="evt">
                <Select v-model="evt.search.dimensionId" clearable placeholder="选择组织纬度" style="width:200px">
                    <Option v-for="item in dimensionList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
                <Input v-model="evt.search.nameOrCode" placeholder="名称" style="width:200px"></Input>
                <Select v-model="evt.search.orgCat" clearable placeholder="标签" style="width:200px">
                    <Option v-for="item in labelType" :value="item.dictKey" :key="item.dictKey">{{ item.dictValue }}</Option>
                </Select>
            </template>
            <template #operate>
                <CheckboxGroup v-model="selectedLabelType" style="padding-right: 10px;">
                    <Checkbox v-for="item in labelType" :label="item.dictKey" :key="item.dictKey">{{ item.dictValue }}</Checkbox>
                </CheckboxGroup>
                <Button type="info" :disabled="!selection || !selection.length" @click="onSubmitBatch">确定批量设置</Button>
            </template>
        </CompTable>
    </div>
</template>

<script>
import CompTable from "../../jointly/components/comp-table.vue"
import CompModal from "../../residentdatabase/components/CompModal.vue"
import ElemCheck from "../../customform/components/ElemCheck"
import Request from "../../jointly/utils/request"

export default {
    components: { CompTable, CompModal },

    data() {
        return {
            // 表格接口
            tableApi: null,
            // 维度列表
            dimensionList: null,
            // 标签类型
            labelType: null,
            // 已选标签类型
            selectedLabelType: [],
            // 表格选中项
            selection: [],

            columns: [
                {
                    title: "组织名称",
                    key: "orgName",
                },
                {
                    title: "机构组织编码",
                    key: "orgCode",
                },
                {
                    title: "所属组织纬度",
                    render: (h, params) => {
                        return h("span", this.dimensionList?.find(v => params.row.dimensionId === v.id)?.name || "-")
                    },
                },
                {
                    title: "标签设置",
                    minWidth: 450,
                    align: "center",
                    render: (h, params) => {
                        return h(ElemCheck, {
                            props: {
                                value: params.row.orgCat?.split(",") || [],
                                multiple: true,
                                list: this.labelType.map(item => ({
                                    name: item.dictValue,
                                    value: item.dictKey,
                                })),
                            },
                            on: {
                                "on-change": res => {
                                    const value = res.value.map(v => v.value).join(",")

                                    // 相同值不处理，排序不同也不处理
                                    if (
                                        value === params.row.orgCat ||
                                        (!value && !params.row.orgCat) ||
                                        value
                                            ?.split(",")
                                            .sort()
                                            .join(",") ===
                                            params.row.orgCat
                                                ?.split(",")
                                                .sort()
                                                .join(",")
                                    ) {
                                        return
                                    }

                                    // 延迟 500ms 执行，避免导致动画卡顿
                                    setTimeout(() => {
                                        Request.post(
                                            "/gateway/org/pc/organization/settingOrgLabel",
                                            {
                                                orgCat: value,
                                                orgIds: [String(params.row.orgId)],
                                            },
                                            { json: true }
                                        ).then(() => {
                                            this.$Message.success("设置成功")
                                            // 写入新值
                                            params.row.orgCat = value
                                        })
                                    }, 500)
                                },
                            },
                        })
                    },
                },
            ],
        }
    },

    async mounted() {
        this.getLabelType()
        await this.getDimensionList()

        if (this.dimensionList?.length) {
            // 默认取第一个纬度
            this.tableApi = `/gx/pc/organization/queryOrgPageByDimensionId?dimensionId=${this.dimensionList[0].id}&oemCode=${parent.vue.oemInfo.oemCode}`
        }
    },

    methods: {
        /**
         * 获取纬度列表
         */
        async getDimensionList() {
            await Request.get("/gx/pc/dimension/selectExceptOneByOemCode", {
                oemCode: parent.vue.oemInfo.oemCode,
            }).then(res => {
                this.dimensionList = res
            })
        },

        /**
         * 获取标签类型
         */
        getLabelType() {
            Request.dict("ORG_TYPE").then(res => {
                this.labelType = res
            })
        },

        /**
         * 提交批量设置
         */
        onSubmitBatch() {
            const s = this.selection

            if (!s.length) {
                return this.$Message.warning("请先选择需要批量设置的机构")
            }

            const value = this.selectedLabelType.join(",")

            Request.post(
                "/gateway/org/pc/organization/settingOrgLabel",
                {
                    orgCat: value,
                    orgIds: s.map(v => String(v.orgId)),
                },
                { json: true }
            ).then(() => {
                this.$Message.success("批量设置成功")

                s.forEach(v => {
                    v.orgCat = value
                })

                // 清除批量类型勾选
                this.selectedLabelType = []
                // 清除表格选中项
                this.$refs.comp_table.clearSelecteds()
            })
        },

        /**
         * 监听表格选中项变化
         */
        onChangeSelection(evt) {
            this.selection = evt.value
        },
    },
}
</script>
<style lang="less">
.modal-operate-btn {
    cursor: pointer;
    padding: 4px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2faaf7;
    color: #fff;
    border-radius: 4px;
}

.organization-home-set-up {
    .model-content {
        height: 100%;
        display: flex;
        flex-direction: column;

        .dimension-box {
            margin: 15px 10px 5px 10px;
            padding-bottom: 15px;
            border-bottom: 1px solid #f3f3f3;
            display: flex;
            align-items: center;

            .select-box {
                height: 40px;
                width: 300px;
                line-height: 40px;
                padding: 0 10px;
                border-radius: 4px;
                border: 1px solid #e3e3e3;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;
                overflow: hidden;

                p {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .empty {
                    color: #999;
                }

                .ivu-icon {
                    margin-left: 20px;
                    flex-shrink: 0;
                }
            }
        }
    }

    .model-table {
        display: flex;
        flex-grow: 1;

        .table-item {
            position: relative;
            flex: 1;
        }
    }
}
</style>
